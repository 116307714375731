import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const messages = {
  es: {
    home: "HOME",
    "GALERIA 3D": "GALERÍA 3D",
    "TOUR VIRTUAL": "TOUR VIRTUAL",
    "3D FILM": "3D FILM",
    CATÁLOGO: "CATÁLOGO",
    "APP PERSONALIZACIÓN PARTNERS": "APP PERSONALIZACIÓN",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTACTO",
    intro1:
      "Esta promoción ha confiado en la experiencia de Porcelanosa Grupo y sus más de 50 años de trayectoria en el sector residencial para que el 100% de los acabados de nuestras viviendas sean de calidad y puedas tener la máxima garantía.",
    intro2_partners:
      "Junto a Porcelanosa Partners, el área especializada en proyectos residenciales, hemos desarrollado las soluciones más apropiadas acorde a las necesidades del proyecto, contando siempre con el asesoramiento y supervisión directa de sus mejores profesionales y toda nuestra dedicación.",
    intro2_select:
      "Junto a Porcelanosa Select, el área especializada en proyectos residenciales, hemos desarrollado las soluciones más apropiadas de acuerdo con las necesidades del proyecto, contando siempre con el asesoramiento y supervisión directa de los mejores profesionales y toda nuestra dedicación.",
    "Galeria 3D": "Galería 3D",
    Galeria_subtitle:
      "Deja de imaginar y empieza a visualizar<br> las estancias de tu futura vivienda.",
    "Ver todos los acabados": "acabados <br> disponibles",
    SALÓN: "SALÓN",
    COCINA: "COCINA",
    "BAÑO 1": "BAÑO 1",
    "BAÑO 2": "BAÑO 2",
    FACHADA: "FACHADA",
    HABITACIÓN: "DORMITORIO",
    "Tour Virtual": "Tour Virtual",
    tour_virtual_subtitle:
      "Recorrido virtual por las diferentes<br> estancias de la vivienda.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle":
      "Experiencia audiovisual del interior<br> de la vivienda con la que ver los<br> ambientes con vida.",
    Catálogo: "Catálogo",
    catalog1:
      "Catálogo para ver la presentación<br> de la promoción residencial y elegir<br> acabados.",
    Descargar: "Descargar",
    "App personalización partners": "App personalización",
    "App personalización select": "Select Space",
    spaces1_partners:
      "App Porcelanosa Partners Spaces<br> para personalizar los ambientes del<br> residencial y seleccionar de acabados.",
    spaces1_select: "Combina, elige y comparte<br> tu estancia personalizada.",
    Contacto: "Contacto",
    "Dirección Promoción": "Dirección Promoción",
    "Dirección Showroom": "Dirección Showroom",
    "Todos los derechos reservados.": "Todos los derechos reservados.",
  },
  en: {
    home: "HOME",
    "GALERIA 3D": "3D GALLERY",
    "TOUR VIRTUAL": "VIRTUAL TOUR",
    "3D FILM": "3D FILM",
    CATÁLOGO: "CATALOGUE",
    "APP PERSONALIZACIÓN PARTNERS": "CUSTOMIZATION APP",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTACT",
    intro1:
      "This promotion has relied on the experience of Porcelanosa Grupo and its more than 50 years of experience in the residential sector so that 100% of the finishes in our homes are of quality and you can have the maximum guarantee.",
    intro2_partners:
      "Together with Porcelanosa Partners, the area specialized in residential projects, we have developed the most appropriate solutions according to the needs of the project, always counting on the advice and direct supervision of its best professionals and all our dedication.",
    intro2_select:
      "Together with Porcelanosa Select, the area specialized in residential projects, we have developed the most appropriate solutions according to the needs of the project, always counting on the advice and direct supervision of the best professionals and all our dedication.",
    "Galeria 3D": "3D Gallery",
    Galeria_subtitle: "Stop dreaming, start watching.",
    "Ver todos los acabados": "all finishes",
    SALÓN: "LIVING ROOM",
    COCINA: "KITCHEN",
    "BAÑO 1": "BATHROOM 1",
    "BAÑO 2": "BATHROOM 2",
    FACHADA: "FACADE",
    HABITACIÓN: "BEDROOM",
    "Tour Virtual": "Virtual Tour",
    tour_virtual_subtitle: "The experience of getting involved.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle": "The experience of getting involved.",
    Catálogo: "Catalogue",
    catalog1: "All products organised<br> to close the sale with the client.",
    Descargar: "Download",
    "App personalización partners": "App customization",
    "App personalización select": "Select Space",
    spaces1_partners:
      "Porcelanosa Partners Spaces app<br> to customize residential settings and<br> select finishes.",
    spaces1_select: "Combine, choose and share<br> your customised room.",
    Contacto: "Contact",
    "Dirección Promoción": "Promotion Address",
    "Dirección Showroom": "Showroom Address",
    "Todos los derechos reservados.": "All rights reserved.",
  },
  pt: {
    home: "HOME",
    "GALERIA 3D": "GALERIA 3D",
    "TOUR VIRTUAL": "VISITA VIRTUAL",
    "3D FILM": "FILME 3D",
    CATÁLOGO: "CATÁLOGO",
    "APP PERSONALIZACIÓN PARTNERS": "APLICATIVO DE PERSONALIZAÇÃO",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTATO",
    intro1:
      "Esta promoção contou com a experiência da Porcelanosa Grupo e os seus mais de 50 anos de experiência no setor residencial para que 100% dos acabamentos das nossas casas sejam de qualidade e tenha a máxima garantia.",
    intro2_partners:
      "Junto com a Porcelanosa Partners, a área especializada em projetos residenciais, desenvolvemos as soluções mais adequadas às necessidades do projeto, contando sempre com o aconselhamento e supervisão direta dos seus melhores profissionais e toda a nossa dedicação.",
    intro2_select:
      "Junto com a Porcelanosa Select, a área especializada em projetos residenciais, desenvolvemos as soluções mais adequadas às necessidades do projeto, contando sempre com o aconselhamento e supervisão direta dos seus melhores profissionais e toda a nossa dedicação.",
    "Galeria 3D": "Galeria 3D",
    Galeria_subtitle:
      "Pare de imaginar e comece a visualizar os cômodos da sua futura casa.",
    "Ver todos los acabados": "todos os acabamentos",
    SALÓN: "SALA DE ESTAR",
    COCINA: "COZINHA",
    "BAÑO 1": "BANHEIRO 1",
    "BAÑO 2": "BANHEIRO 2",
    FACHADA: "FACHADA",
    HABITACIÓN: "QUARTO",
    "Tour Virtual": "Visita Virtual",
    tour_virtual_subtitle: "Tour virtual pelos diferentes<br> cômodos da casa.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle":
      "Experiência audiovisual do interior<br> da casa para ver os<br> ambientes vivos.",
    Catálogo: "Catálogo",
    catalog1:
      "Catálogo para ver a apresentação<br> do empreendimento residencial e escolher<br> acabamentos.",
    Descargar: "Baixar",
    "App personalización partners": "Personalização do aplicativo",
    "App personalización select": "Select Space",
    spaces1_partners:
      "Aplicativo Porcelanosa Partners Spaces<br> para personalizar ambientes residenciais<br> e selecionar acabamentos.",
    spaces1_select:
      "Combine, escolha e partilhe<br> a sua estadia personalizada.",
    Contacto: "Contato",
    "Dirección Promoción": "Endereço da promoção",
    "Dirección Showroom": "Endereço do Showroom",
    "Todos los derechos reservados.": "Todos os direitos reservados.",
  },
  it: {
    home: "HOME",
    "GALERIA 3D": "GALLERIA 3D",
    "TOUR VIRTUAL": "TOUR VIRTUALE",
    "3D FILM": "FILM 3D",
    CATÁLOGO: "CATALOGO",
    "APP PERSONALIZACIÓN PARTNERS": "APP PERSONALIZZAZIONE",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTATTO",
    intro1:
      "Questa promozione è basata sull'esperienza di Porcelanosa Grupo e i suoi oltre 50 anni di storia nel settore residenziale affinché il 100% delle finiture delle nostre case sia di qualità per ottenere la massima garanzia.",
    intro2_partners:
      "Insieme a Porcelanosa Partners, l'area specializzata in progetti residenziali, abbiamo sviluppato le migliori soluzioni per le esigenze del progetto, facendo sempre affidamento sull’appassionata consulenza dei suoi migliori professionisti.",
    intro2_select:
      "Insieme a Porcelanosa Select, l'area specializzata in progetti residenziali, abbiamo sviluppato le migliori soluzioni per le esigenze del progetto, facendo sempre affidamento sull’appassionata consulenza dei suoi migliori professionisti.",
    "Galeria 3D": "Galleria 3D",
    Galeria_subtitle: "Basta di sognare,<br> ora puoi guardare le tue stanze.",
    "Ver todos los acabados": "tutte le finiture",
    SALÓN: "SALOTTO",
    COCINA: "CUCINA",
    "BAÑO 1": "BAGNO 1",
    "BAÑO 2": "BAGNO 2",
    FACHADA: "FACCIATA",
    HABITACIÓN: "CAMERA DA LETTO",
    "Tour Virtual": "Tour virtuale",
    tour_virtual_subtitle: "L'esperienza di lasciarsi avvolgere.",
    "3D Film": "3D Film",
    "3dfilm_Subtitle": "L'esperienza di lasciarsi avvolgere.",
    Catálogo: "Catalogo",
    catalog1:
      "Tutti i prodotti organizzati<br> per confermare la vendita con il cliente.",
    Descargar: "Download",
    "App personalización partners": "App Personalizzazione",
    "App personalización select": "Select Space",
    spaces1_partners:
      "App Porcelanosa Partners Spaces<br> per personalizzare gli ambienti residenziali<br> e selezionare le finiture.",
    spaces1_select:
      "Combina, scegli e condividi<br> la tua stanza personalizzata.",
    Contacto: "Contatto",
    "Dirección Promoción": "Indirizzo promozione",
    "Dirección Showroom": "Indirizzo showroom",
    "Todos los derechos reservados.": "Tutti i diritti riservati.",
  },
  fr: {
    home: "HOME",
    "GALERIA 3D": "GALERIE 3D",
    "TOUR VIRTUAL": "TOUR VIRTUEL",
    "3D FILM": "FILM 3D",
    CATÁLOGO: "CATALOGUE",
    "APP PERSONALIZACIÓN PARTNERS": "APPLICATION DE PERSONNALISATION",
    "APP PERSONALIZACIÓN SELECT": "SELECT SPACE",
    CONTACTO: "CONTACT",
    intro1:
      "Cette promotion s'est appuyée sur l'expérience de Porcelanosa Grupo et ses 50 ans d'expérience dans le secteur résidentiel afin que 100% des finitions de nos maisons soient de qualité et que vous puissiez avoir la garantie maximale.",
    intro2_partners:
      "En collaboration avec Porcelanosa Partners, le domaine spécialisé dans les projets résidentiels, nous avons développé les solutions les plus appropriées en fonction des besoins du projet, en comptant toujours sur les conseils et la supervision directe de ses meilleurs professionnels et sur tout notre dévouement.",
    intro2_select:
      "En collaboration avec Porcelanosa Select, le domaine spécialisé dans les projets résidentiels, nous avons développé les solutions les plus appropriées en fonction des besoins du projet, en comptant toujours sur les conseils et la supervision directe des meilleurs professionnels.",
    "Galeria 3D": "Galerie 3D",
    Galeria_subtitle: "Arrêtez d'imaginer,<br> commencez à visualiser.",
    "Ver todos los acabados": "toutes les finitions",
    SALÓN: "SALON",
    COCINA: "CUISINE",
    "BAÑO 1": "SALLE DE BAIN 1",
    "BAÑO 2": "SALLE DE BAIN 2",
    FACHADA: "FAÇADE",
    HABITACIÓN: "CHAMBRE",
    "Tour Virtual": "Tour virtuel",
    tour_virtual_subtitle: "L'expérience de se laisser attraper.",
    "3D Film": "Film 3D",
    "3dfilm_Subtitle": "L'expérience de se laisser attraper.",
    Catálogo: "Catalogue",
    catalog1:
      "Tous les produits organisés<br> pour concrétiser la vente avec le client.",
    Descargar: "Télécharger",
    "App personalización partners": "Personnalisation de l'application",
    "App personalización select": "Select Space",
    spaces1_partners:
      "Application Porcelanosa Partners Spaces<br> pour personnaliser les environnements<br> résidentiels et sélectionner les finitions.",
    spaces1_select:
      "Combinez, choisissez et partagez<br> votre séjour personnalisé.",
    Contacto: "Contactez-nous",
    "Dirección Promoción": "Adresse promotion",
    "Dirección Showroom": "Adresse de la salle d'exposition",
    "Todos los derechos reservados.": "Tous droits réservés.",
  },
};
export const i18n = new VueI18n({
  locale: window.navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages,
});
