import Vue from "vue";

/*TITLE*/
document.title = "Freedom Townhomes | Porcelanosa SELECT";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "SELECT";

/*HERO*/
Vue.prototype.$title = "Freedom Townhomes";
Vue.prototype.$subtitle = "PACIFIC POINT REALTY LLC";

/*INTRO*/
Vue.prototype.$promoter = "Freedom Townhomes";
Vue.prototype.$introSubtitle = "PACIFIC POINT REALTY LLC";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "porcelanosa-freedom-townhomes-interiores-salon-op2--20231013111035.jpg";
Vue.prototype.$image_kitchen = "porcelanosa-freedom-townhomes-interiores-cocina-op3--20231013111034.jpg";
Vue.prototype.$image_bath1 = "porcelanosa-freedom-townhomes-interiores-bano-ppal-op3--20231013111008.jpg";
Vue.prototype.$image_bath2 = "porcelanosa-freedom-townhomes-interiores-bano-sec-op3--20231013111036.jpg";
Vue.prototype.$image_room = "porcelanosa-freedom-townhomes-interiores-dormitorio-ppal-op2--20231013111002.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "porcelanosa-freedom-townhomes-interiores-salon-op2--20231013111035.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-salon--20231013111016.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "porcelanosa-freedom-townhomes-interiores-cocina-op3--20231013111034.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-cocina-op2--20231013111050.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-cocina--20231013111005.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "porcelanosa-freedom-townhomes-interiores-bano-ppal-op3--20231013111008.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-bano-ppal-op2_ret--20231013111024.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-bano-ppal--20231013111039.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "porcelanosa-freedom-townhomes-interiores-bano-sec-op3--20231013111036.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-bano-sec-op2--20231013111058.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-bano-sec--20231013111011.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "porcelanosa-freedom-townhomes-interiores-dormitorio-ppal-op2--20231013111002.jpg",
  },
  {
    src: "porcelanosa-freedom-townhomes-interiores-dormitorio-ppal--20231013111015.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://clientes.ppgstudios.com/porcelanosa/Freedom_Townhomes/tour/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/fI864_Y95zk";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/en/tag/freedomtownhomesatlanta/?g=415&e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=1099%20north%20ave%20ne%20atlanta%20georgia%2030307%20georgia%20",
    text: "1099 North Ave NE Atlanta, Georgia, 30307 (Georgia)",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:+1404.874.0300",
    text: "+1404.874.0300",
  },
  {
    icon: "mdi-email",
    link: "mailto:allensnow@atlantafinehomes.com",
    text: "allensnow@atlantafinehomes.com",
  },
];
